<template>
    <v-col cols="6">
        <v-card-title class="text-h5 primary--text font-weight-bold d-flex align-center justify-center">
           Donation sent to Tally
        </v-card-title>
        <v-toolbar
        dark
        color="primary"
        >
            <v-text-field
                v-model.trim="search"
                flat
                solo-inverted
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
            ></v-text-field>
            <div class="mx-3 text-subtitle-1" v-if="grandTotal" >Total: {{ $filters.currencyComma(grandTotal) }}</div>
        </v-toolbar>
        <datatable @metaResp="meta=$event" ref="dtComponent" :customActionDisable="loading" :modelData="dtData" :search="search"
            @customAction="sendHeldDonate($event.item)" @interSectLoad="$refs.dtComponent.interSectLoading = $event"/>
    </v-col>
  
</template>

<script>
import { mapActions } from 'vuex';

import { CurrencyFormat } from "@/directives";

export default {
    props: ['wsBind'],
    components: {
        Datatable: () => import("@/components/common/datatable"),
    },
    directives: {
        'currency': CurrencyFormat,
    },
    data: () => ({
        search: '',
        loading: false,
        dtData: {
            searchDisable: true,
            headers: [,
                { text: 'Date/Time', align: 'start', value: 'datefield', field: 'created_at'},
                { text: 'Name', align: 'start', value: 'donor_name'},
                { text: 'Campaign', align: 'start', value: 'campaign'},
                { text: 'Amount', align: 'start', value: 'amount'},
                {text: 'Payment Method', value: 'payment_type_label'},
                {text: 'Source', value: 'source'},
                {text: 'Action', value: 'action', sortable: false}
            ],
            expandable: false,
            actions: [],
            headerActions: [],
            customButtonActions:[{
                icon: 'mdi-hand-back-left-outline',
                tooltipName: 'Hold Donation'
            }],
            footerHide: true,
            interSectScroll: true,
            interSectId: 'tally-tally-table',
            interSectClass: 'tally-scroll-table',
            pageLink: `${window.VUE_APP_MS_URL}donate/donates?ac=1&if=0&meta=1&page=1`
        },
        meta: {},
        grandTotal: 0
    }),
    watch: {
        wsBind(newVal) {
            const itemIndex = newVal ? this.$refs.dtComponent.dtLists.findIndex(item => item.id === newVal.id) : -1;
            if (newVal && newVal.inspiration_fund == 0) {
                if (itemIndex !== -1) this.$refs.dtComponent.dtLists[itemIndex] = newVal;
                else if (this.$refs.dtComponent.dtLists.length == 0) this.$refs.dtComponent.pageLoading(this.dtData.pageLink);
                else {
                    this.$refs.dtComponent.dtLists.unshift(newVal);
                    this.grandTotal = (Number(this.grandTotal) || 0) + (Number(newVal.amount) || 0);
                }
            } else {
                if (itemIndex !== -1) {
                    this.grandTotal = (Number(this.grandTotal) || 0) - (Number(this.$refs.dtComponent.dtLists[itemIndex].amount) || 0);
                    this.$refs.dtComponent.dtLists.splice(itemIndex, 1);
                }
            }
        },
        meta(newValue) {
            if(newValue) this.grandTotal = Number(newValue.total_amount) || 0;
        }
    },
    methods: {
        ...mapActions(["updateDonate"]),

        sendHeldDonate(donateItem) {
            this.loading = true;
            const reqData = {
                id: donateItem.id,
                inspiration_fund: 1
            };
            this.updateDonate(reqData).then(resp => {
                this.$store.commit("snackbar/SHOW_MESSAGE", {
                    text: "Donation Updated Successfully",
                    color: "success",
                });
                this.loading = false;
            }).catch(err => this.loading = false);
        }
    }
}
</script>
